/* stylelint-disable no-invalid-position-at-import-rule */

// ===============
// Custom.scss
// Option B: Include parts of BSB + Bootstrap
// https: //getbootstrap.com/docs/5.2/customize/sass/
// ===============

// Functions
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/functions";

// Variable Overrides
//@import "./variables";
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/variables";
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/variables-dark";

// BSB Variables (New / Overwrites / Marger)
@import "./bsb-variables";

// Map Overrides
//@import "./maps";
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/maps";

// BSB Maps (New / Overwrites / Marger)
//@import "./bsb-maps";

// Mixins
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/mixins";
//@import "./mixins/grid";

// Utilities Extension
@import "./utilities";

// Other Parts
//@import "./grid";

// Utilities API
@import "../../../lib/bootstrap/bootstrap-5.3.0/scss/utilities/api";

// BSB Extension
@import "./bsb-extension";

/* stylelint-disable function-comma-space-after */
/* stylelint-disable function-parentheses-space-inside */
/* stylelint-disable scss/no-global-function-names */
/* stylelint-disable indentation */
// ===============
// Utilities Client
// ===============

$utilities: () !default;
$utilities: map-merge(
  (
    // Text
    "font-size": (
      rfs: true,
      property: font-size,
      class: fs,
      values: $font-sizes
    ),
    // Padding Utiities
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers
    ),
  ),
  $utilities
);
